import { Enums } from "./common.js";
import Vue from 'vue';
import store from "./store.js";

export default function Popup(type, titleName = null, fileUrl = null, fileName = null) {
  return new Promise(( resolve ) => {

    let popupParams = {};

    switch(type) {
      case Enums.PopupType.EVENT_CHANGE:
        popupParams = {
          title: "Are you sure",
          message: `you want to change '${ titleName }'?`,
          button: {
            no: "Cancel",
            yes: "Yes"
          },
        }
        break;

      case Enums.PopupType.EVENT_REMINDER:
        popupParams = {
          title: "Event reminder",
          message: `All group members will receive a push notification.
            Select below if you’d like to also notify them by email. 
            <div class="checkbox-holder">
              <div class="checkbox-item">
                <input 
                  type="checkbox"
                  id="reminder"
                  name="reminder"
                  value="1"
                />
                <label for="reminder"> Email reminder </label>
              </div>
            </div>`,
          button: {
            no: "Cancel",
            yes: "Send"
          },
        }
        break;

      case Enums.PopupType.EDIT_RECURRING_EVENT:
      case Enums.PopupType.CANCEL_RECURRING_EVENT:
        popupParams = {
          title: `${ type.value } recurring event`,
          message: `
            <div class="radio-holder">
              <div class="radio-item">
                <input type="radio"
                  id="current"
                  checked
                  name="recurring"
                  value="${ Enums.RecurrenceUpdate.ONE }"
                />
                <label for="current"> This event </label>
              </div>
  
              <div class="radio-item">
                <input
                  type="radio"
                  id="currentFollowing"
                  name="recurring"
                  value="${ Enums.RecurrenceUpdate.FOLLOWING }" 
                />
                <label for="currentFollowing"> This and following events </label>
              </div>
  
              <div class="radio-item">
                <input
                  type="radio"
                  id="all"
                  name="recurring"
                  value="${ Enums.RecurrenceUpdate.All }"
                />
                <label for="all"> All events </label>
            </div>
          `,
          button: {
            no: "Cancel",
            yes: "Ok"
          },
        }
        break;

      case Enums.PopupType.PULBISH_EVENT:
      case Enums.PopupType.PUBLISH_POST: {
        popupParams = {
          title: "Are you sure",
          message: `you want to publish '${ titleName }'?`,
          button: {
            no: "Cancel",
            yes: "Publish"
          },
        }
        break;
      }

      case Enums.PopupType.CANCEL_EVENT:
      case Enums.PopupType.CANCEL_POST: {
        let cancelTarget = type === Enums.PopupType.CANCEL_EVENT ? "event" : "post";  // Target "event" or "post" is canceled
        popupParams = {
          title: "Are you sure",
          message: `you want to cancel '${ titleName }'?`,
          button: {
            no: "Cancel",
            yes: `Cancel ${ cancelTarget }`
          },
        }
        break;
      }

      case Enums.PopupType.DELETE_EVENT:
      case Enums.PopupType.DELETE_POST:
      case Enums.PopupType.DELETE_GROUP:
        popupParams = {
          title: "Are you sure",
          message: `you want to delete '${ titleName }'?`,
          button: {
            no: "Cancel",
            yes: "Delete"
          },
        }
        break;

      case Enums.PopupType.DISCARD_CHANGES:
        popupParams = {
          message: "Discard unsaved changes?",
          button: {
            no: "Cancel",
            yes: "Discard"
          },
        }
        break;

      case Enums.PopupType.DISABLE_LINK:
        popupParams = {
          title: "Confirmation",
          message: `The link will no longer work. There is no undo. Go ahead? `,
          button: {
            no: "Cancel",
            yes: "Disable link"
          },
        }
        break;

      case Enums.PopupType.GENERATE_LINK:
        popupParams = {
          title: "Confirmation",
          message: `The old link will no longer work. There is no undo. Make sure to send the new link to anyone who needs to join your group! Go ahead? `,
          button: {
            no: "Cancel",
            yes: "Generate new link"
          },
        }
        break;

      case Enums.PopupType.DELETE_ZOOM:
        popupParams = {
          title: "Are you sure",
          message: `you'd like to delete this Zoom conference?`,
          button: {
            no: "No",
            yes: "Cancel"
          },
        }
        break;

      case Enums.PopupType.DISCONNECT_ZOOM:
        popupParams = {
          title: "Are you sure",
          message: `you want to disconnect zoom?`,
          button: {
            no: "Cancel",
            yes: "Disconnect"
          },
        }
        break;

      case Enums.PopupType.REMOVE_MEMBER:
        popupParams = {
          title: "Are you sure",
          message: `you want to remove ${ titleName }?`,
          button: {
            no: "Cancel",
            yes: "Remove"
          },
        }
        break;

      case Enums.PopupType.FILE_IMPORT:
        popupParams = {
          title: `${ titleName } import`,
          message: `To import your <span>${ titleName }</span> 
                      please download and use the <a href="${ fileUrl }"> import template</a>.`,
          button: {
            no: "Cancel",
            yes: "Import"
          },
        }
        break;

      case Enums.PopupType.RESULTS_IMPORT:
        popupParams = {
          title: `${ titleName } imported`,
          message: `Your file ‘${ fileName }’ has been imported.<br/>
          Please download and review the <a href="${ fileUrl }">import results</a>.`,
          button: {
            no: "Close"
          },
        }
        break;

      case Enums.PopupType.DELETE_ACCOUNT:
        popupParams = {
          title: "Are you sure",
          message: `you want to delete your account?`,
          button: {
            no: "No",
            yes: "Delete"
          },
        }
        break;

      default: 
        return; //ToFix: Throw
    }

    // Hide scroll when popup is open
    store.commit("setLockBodyScroll", true);

    // Set 'danger' or 'info' variant
    store.commit("setVariantPopupConfirm", type.variant);

    // Callback
    popupParams.callback = (confirm) => {
      resolve(confirm);
      // Removing popup variant and showing scrollbar
      store.commit("setLockBodyScroll", false);
      store.commit("setVariantPopupConfirm", false);
    } 

    // Show popup
    Vue.$confirm(popupParams)
  })
}