import Vue from "vue";
import App from "./App.vue";
// import './registerServiceWorker';
import router from "./router.js";
import store from "./store.js";
import { mapGetters, mapMutations } from "vuex";
import VueCookies from "vue-cookies";
import Notifications from "vue-notification";
import axios from "axios";
import firebase from "firebase/compat/app";
import urls, { GetAPIUrl } from "./urls";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import VTooltip from "v-tooltip";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VueLocalStorage from "vue-localstorage";
import VueConfirmDialog from "vue-confirm-dialog/src";
import Paginate from "vuejs-paginate";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueCarousel from "vue-carousel";

import { Enums, RegExp } from "./common.js";
import Popup from "./popups.js";

// Global variable
Vue.prototype.$YouToo = { 
  Enums, 
  RegExp,
  Popup
}


import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay
} from "swiper";

SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);

import "vue2-datepicker/index.css";
import "vue-select/dist/vue-select.css";

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(Notifications);
Vue.use(VueCarousel);
Vue.use(require("vue-moment"));

Vue.use(VTooltip, {
  defaultBoundariesElement: document.body
});
Vue.use(VueLocalStorage);
Vue.use(VueConfirmDialog);
Vue.use(VueAwesomeSwiper);
let FIREBASE_CONFIG;
let ID_FIREBASE_PROJECT;

if (window.FB_CONFIG) {
  FIREBASE_CONFIG = window.FB_CONFIG;
} else {
  ID_FIREBASE_PROJECT = "eventapp2-dev";
  FIREBASE_CONFIG = {
    apiKey: "AIzaSyCYqNzSSWj8ATSIkVOfNlUGi-HJz8O_XIA",
    authDomain: ID_FIREBASE_PROJECT + ".firebaseapp.com",
    databaseURL: "https://" + ID_FIREBASE_PROJECT + ".firebaseio.com",
    projectId: ID_FIREBASE_PROJECT,
    storageBucket: "eventapp2-dev.appspot.com",
    messagingSenderId: "1074560571174",
    appId: "1:1074560571174:web:be078fd8fc3d1f4f8693e7",
    measurementId: "G-FK104DWNRL"
  };
}

Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  }
});

firebase.initializeApp(FIREBASE_CONFIG);

Vue.component("c-button", () => import("@/lib/framework/actions/buttons/button"));
Vue.component("c-input", () => import("@/lib/framework/inputs/input"));
Vue.component("c-textarea", () => import("@/lib/framework/inputs/textarea"));
Vue.component("c-checkbox", () => import("@/lib/framework/inputs/checkbox"));
Vue.component("c-select", () => import("@/lib/framework/inputs/select"));
Vue.component("c-form-group", () => import("@/lib/components/forms/formItem"));

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("DatePicker", DatePicker);
Vue.component("v-select", vSelect);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
Vue.component("paginate", Paginate);
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

Vue.prototype.$http = axios.create({
  baseURL: urls.BASE_API_URL
});


/* ------ REFRESH TOKEN ------ */
let isRefreshing = false;
let refreshSubscribers = [];

Vue.prototype.$http.interceptors.response.use(
  (response) => {
    console.log("no error Vue.prototype.$http.interceptors", isRefreshing);
    return response;
  },
  (error) => {
    const {
      config,
      response: { status }
    } = error;
    const originalRequest = config;
    if (status === 401) {
      console.log("401 inside Vue.prototype.$http.interceptors");
      if (!isRefreshing) {
        console.log("!isRefreshing");
        isRefreshing = true;
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            console.log("has user");
            firebase
              .auth()
              .currentUser.getIdToken(true) // forceRefresh
              .then(async (response) => {
                console.log("after force refresh token", "response", response);
                Vue.$cookies.set("userEventAppToken", response);
                isRefreshing = false;
                onRrefreshed(Vue.$cookies.get("userEventAppToken"));
              })
              .catch((e) => console.log("Tried to refresh token", e));
          } else {
            if (firebase.auth().currentUser) store.dispatch("userLogout");
          }
        });
      }

      return new Promise((resolve) => {
        console.log("subscribeTokenRefresh Promise inside");
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          originalRequest.headers["Authorization"] = "Bearer " + token;
          console.log("subscribeTokenRefresh originalRequest");
          resolve(axios(originalRequest));
        });
      });
    } else {
      console.log("not 401, reject");
      return Promise.reject(error);
    }
  }
);
const subscribeTokenRefresh = (cb) => {
  console.log("subscribeTokenRefresh", cb);
  refreshSubscribers.push(cb);
};
const onRrefreshed = (token) => {
  console.log("onRrefreshed", token);
  refreshSubscribers.map((cb) => cb(token));
  refreshSubscribers = [];
};
/* ------ REFRESH TOKEN ------ */

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  let token = Vue.$cookies.get("userEventAppToken");

  if (requiresAuth) {
    token ? next() : next(`/signin`);
  } else {
    next();
  }
});

Vue.mixin({
  computed: mapGetters([
    "getLoadingStatus",
    "getCurrentUser",
    "getHelpStatus",
    "getActiveModal",
    "getActiveSidebar",
    "getGroup",
    "getNotifications",
    "getActivePage"
  ]),
  methods: {
    ...mapMutations([
      "setLoadingStatus",
      "setHelpStatus",
      "setCurrentUser",
      "setGroups",
      "setGroup",
      "setNotifications",
      "setEvent",
      "setPost",
      "setActivePage"
    ]),
    myTest(t) {
      console.log(t);
    },
    resolveError(error) {
      if (!error.response) {
        Vue.notify({
          group: "hint",
          title: "Oops",
          text: "Network unavailable: " + error.response.data,  // ToDo: 'Internet disconnected' - Default Error?
          type: "error"
        });
        return;
      }
      if (error.response.data.errors && error.response.data.errors.Get) {
        console.log("error:", error.response.data.errors.Get[0]);
      } else if (
        error.response.data.errors &&
        error.response.data.errors.Update
      ) {
        console.log("error:", error.response.data.errors.Update[0]);
      } else if (
        error.response.data.errors &&
        error.response.data.errors.Delete
      ) {
        console.log("error:", error.response.data.errors.Delete[0]);
      } else {
        console.log(error.response.data);
      }

      if (error.response.status === 400) {
        Vue.notify({
          group: "hint",
          title: "Oops",
          text: "400: Bad Request. " + error.response.data,
          type: "error"
        });
        store.dispatch("setFetchStatus", false);
      } else if (error.response.status === 401) {
        Vue.notify({
          group: "hint",
          title: "Oops",
          text: "401 - Unauthorized: " + error.response.data,
          type: "error"
        });
        console.log("401 Unauthorized. " + error.response.data);
        store.dispatch("setFetchStatus", false);
      } else if (error.response.status === 403) {
        Vue.notify({
          group: "hint",
          title: "Oops",
          text: "403 - Forbidden: " + error.response.data,
          type: "error"
        });
        store.dispatch("setFetchStatus", false);
      } else if (error.response.status === 404) {
        console.log("404: Not found: " + error.response.data);
        // ToDo: Top level APIs should redirect to 'Not-found'! Ex. loading invalid\deleted groups, etc....!
      } else if (error.response.status === 409) {
        Vue.notify({
          group: "hint",
          title: "Oops",
          text: "409 - Conflict: " + error.response.data,
          type: "error"
        });
        store.dispatch("setFetchStatus", false);
      } else if (error.response.status === 500) {
        Vue.notify({
          group: "hint",
          title: "Oops",
          text: "500 - Internal server error: " + error.response.data,
          type: "error"
        });
        store.dispatch("setFetchStatus", false);
      }
    },
    // 'makeRequest' returns Success - 'response object' Or Failure - 'string error'!
    makeRequest(  // ToDo: Imp - use named params: https://www.skovy.dev/blog/named-parameters-in-javascript?seed=tadvtt
      url,
      method,
      data = null,
      queryParam = null,
      resolveError = true       // ToDo: Consider "Default" error param to 'makeRequest()' for 'resolveError'
    ) {
      let requestURL;

      if (typeof url === "object") {
        requestURL = GetAPIUrl(url);
      } else {
        requestURL = queryParam ? urls[url] + queryParam : urls[url];
      }

      let headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache"
      };

      if (this.$cookies.get("userEventAppToken")) {
        headers["Authorization"] =
          "Bearer " + this.$cookies.get("userEventAppToken");
      }

      return new Promise((resolve) => {
        this.$http({
          url: requestURL,
          method,
          data,
          headers
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (resolveError) {
            this.resolveError(error);
          }

          resolve(error.response.data);
        });
      });
    },
    uploadImg(url, data) {
      this.setLoadingStatus(true);
      this.uploadPercent = 0;

      return new Promise((resolve) => {
        this.$http({
          url,
          data,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + this.$cookies.get("userEventAppToken")
          },
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader("content-length") ||
                progressEvent.target.getResponseHeader(
                  "x-decompressed-content-length"
                );
            this.uploadPercent = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
          }
        })
          .catch((error) => {
            this.resolveError(error);
          })
          .then((response) => {
            this.uploadPercent = 0;
            if (response && response.status === 200) {
              this.setLoadingStatus(false);
              resolve(response);
            }
          });
      });
    },
    deleteImg(url, data) {
      this.setLoadingStatus(true);
      this.uploadPercent = 0;
      const photo = { ...data.photo, delete: true, binary: "" };
      return new Promise((resolve) => {
        this.$http({
          url,
          method: "POST",
          data: photo,
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + this.$cookies.get("userEventAppToken")
          },
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader("content-length") ||
                progressEvent.target.getResponseHeader(
                  "x-decompressed-content-length"
                );
            this.uploadPercent = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
          }
        })
        .catch((error) => {
          this.resolveError(error);
        })
        .then((response) => {
          this.uploadPercent = 0;
          if (response && response.status === 200) {
            this.setLoadingStatus(false);
            resolve(response);
          } else {
            resolve(false);
          }
        });
      });
    },
    uploadImgWithRezult(url, data) {
      this.setLoadingStatus(true);
      this.uploadPercent = 0;
      return new Promise((resolve, reject) => {
        this.$http({
          url,
          data,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + this.$cookies.get("userEventAppToken")
          },
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader("content-length") ||
                progressEvent.target.getResponseHeader(
                  "x-decompressed-content-length"
                );
            this.uploadPercent = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
          }
        })
          .catch((error) => {
            reject(error);
          })
          .then((response) => {
            this.uploadPercent = 0;
            if (response && response.status === 200) {
              this.setLoadingStatus(false);
              resolve(response);
              console.log("uploadImg", response.data);
            }
          });
      });
    },
    loadCurrentUser() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: urls.API_USER_PROFILE,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + Vue.$cookies.get("userEventAppToken")
          }
        })
          .catch((error) => {
            /*let user = firebase.auth().currentUser;
                        if (!user) {
                            reject(error);
                            return false;
                        }*/

            reject(error);
            this.resolveError(error);
          })
          .then((response) => {
            if (response && response.status === 200) {
              this.setCurrentUser(response.data);
              resolve(response);
              console.log("loadCurrentUser", response.data);
            }
          });
      });
    },
    loadGroups() {
      return new Promise((resolve) => {
        this.setLoadingStatus(true);
        this.$http({
          url: urls.API_GROUP_ADMIN,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + Vue.$cookies.get("userEventAppToken")
          }
        })
          .catch((error) => {
            this.setLoadingStatus(false);
            this.resolveError(error);
          })
          .then((response) => {
            this.setLoadingStatus(false);
            if (response && response.status === 200) {
              this.setGroups(response.data.groups);
              resolve(response);
              console.log("loadGroups", response.data.groups);
            }
            return response;
          });
      });
    },
    loadNotifications(anchor = 20) {
      console.log("anchor", anchor);
      if (anchor - 20 > this.getNotifications.length) return;
      console.log("loadNotifications", anchor);
      return new Promise((resolve) => {
        this.$http({
          url: urls.API_NOTIFICATIONS + `OlderThanAnchor/0/${anchor}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + Vue.$cookies.get("userEventAppToken")
          }
        })
          .catch((error) => {
            this.resolveError(error);
          })
          .then((response) => {
            if (response && response.status === 200) {
              this.setNotifications(response.data);
              resolve(response);
            }
          });
      });
    },
    // 'loadGroup' returns Success - 'response object' Or Failure - 'boolean false'!
    loadGroup(id) {
      return new Promise((resolve) => {
        this.$http({
          url: GetAPIUrl(["API_GROUP_ID", id]),  // ToDo: Refactor all APIs to use 'makeRequest()'!
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + Vue.$cookies.get("userEventAppToken")
          }
        })
        .catch((error) => {
          this.resolveError(error);
        })
        .then((response) => {
          if (response && response.status === 200) {
            // setTimeout(() => {
            this.setGroup(response.data);
            resolve(response);
            // }, 30000);
          }
          else {
            resolve(false);
          }
        });
      });
    },
    reloadGroup() {
      return this.loadGroup(this.$route.params.groupId);
    },
    loadEvent(id) {
      return new Promise((resolve) => {
        this.$http({
          url: GetAPIUrl(["API_EVENT_ID", id]),
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + Vue.$cookies.get("userEventAppToken")
          }
        })
          .catch((error) => {
            this.resolveError(error);
            resolve(false);
          })
          .then((response) => {
            console.log(response);
            if (response && !response.status) {
              resolve(false);
            }
            if (response && response.status === 200) {
              resolve(response);
            }
          });
      });
    },
    loadEventParticipants(id) {
      return new Promise((resolve) => {
        this.$http({
          url: "/Event/" + id + "/Attendees",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + Vue.$cookies.get("userEventAppToken")
          }
        })
          .catch((error) => {
            this.resolveError(error);
          })
          .then((response) => {
            if (response && response.status === 200) {
              resolve(response);
              console.log("loadEventParticipants", id, response.data);
            }
          });
      });
    },
    loadPost(id) {
      return new Promise((resolve) => {
        this.$http({
          url: GetAPIUrl(["API_POST_ID", id]),
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + Vue.$cookies.get("userEventAppToken")
          }
        })
          .catch((error) => {
            this.resolveError(error);
            resolve(false);
          })
          .then((response) => {
            console.log(response);
            if (response && !response.status) {
              resolve(false);
            }
            if (response && response.status === 200) {
              resolve(response);
              console.log("loadPost", id, response.data);
            }
          });
      });
    },
    loadEventQA(id) {
      return new Promise((resolve) => {
        this.$http({
          url: "/Event/" + id + "/QuestionsAnswers",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Authorization: "Bearer " + Vue.$cookies.get("userEventAppToken")
          }
        })
          .catch((error) => {
            this.resolveError(error);
          })
          .then((response) => {
            if (response && response.status === 200) {
              resolve(response);
              console.log("loadEventQA", id, response.data);
            }
          });
      });
    },
    getClassByStatus(status){
      switch (status) {
        case Vue.prototype.$YouToo.Enums.Status.DRAFT:
          return "status-draft-color";
        case Vue.prototype.$YouToo.Enums.Status.PUBLISHED:
          return "status-active-color";
        case Vue.prototype.$YouToo.Enums.Status.COMPLETED:
          return "status-completed-color";
        case Vue.prototype.$YouToo.Enums.Status.CANCELED:
          return "status-canceled-color";
        default:
          return "";
      }
    },
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
